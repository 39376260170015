import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { Col, Row } from 'antd'
import { ROUTES, THEME_KEYS, LocalStorageKeys } from '../constants'
import { useConfigCatClient } from 'configcat-react'
import changeFavicon from '../helpers/changeFavicon'
import Loading from '../components/Loading'
import ErrorDrawer from '../components/DrawerContent/ErrorDrawer'
import './layout.scss'
import jlStyle from '../styles/branding/justlease-config'
import arvalStyle from '../styles/branding/arval-config'


const Layout: FC = () => {
  const location = useLocation()
  const configCatClient = useConfigCatClient()
  const theme = localStorage.getItem(LocalStorageKeys.THEME)
  const [configLoaded, setConfigLoaded] = useState(false)

  const styleFile = useMemo(() => {
    if (theme === THEME_KEYS.JL) {
      return jlStyle
    }
    if (theme === THEME_KEYS.ARVAL) {
      return arvalStyle
    }

    return undefined
  }, [theme])

  useEffect(() => {
    const pullConfigCatValues = async() => {
      await configCatClient.forceRefreshAsync()
      setConfigLoaded(true)
    }
    pullConfigCatValues()
  }, [])

  useEffect(() => {
    if (theme && styleFile) {
      Object.keys(styleFile).forEach((style) => {
        document.body.style.setProperty(style, styleFile[style])
      })
    }
    changeFavicon(Object.values(THEME_KEYS).includes(theme?.split('-')?.[0])
      ? theme?.split('-')?.[0]
      : THEME_KEYS.ARVAL)
  }, [theme])

  const locationPath = location.pathname.substring(1)

  const hasFooter = useCallback(
    () => ROUTES.ROUTES_CONFIG.find(
      (route) => {
        if (locationPath === `${route.path}`
          || location.pathname.substring(1, location.pathname.lastIndexOf('/')) + '/:id' === `${route.path}`) {
          return route.footerText
        }
      },
    ), [location],
  )

  const classes = `main-content ${hasFooter() ? 'has-footer' : ''}`

  return (
    <main className="main-layout">
      {
        configLoaded
          ? <>
            <ErrorDrawer />
            <Header />
            <Row className={classes}>
              <Col flex={1}>
                <Outlet />
              </Col>
            </Row>
            <Footer />
          </>
          : <Loading />
      }

    </main>
  )
}

export default Layout
