import { atom, selector } from 'recoil'
import { getOverview } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'

export const aspectPhotosState = atom({
  key: 'aspectPhotosState',
  default: selector({
    key: 'aspectPhotosState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      return inspectionId ? getOverview(inspectionId) : null
    },
  }),
})